<template>
	<div>
		<site-header></site-header>
		<header-lunbo></header-lunbo>
		<nav-menu></nav-menu>
		<div style="width:100%;height:70px;background:rgba(240, 243, 248, 1)"></div>
	</div>
</template>

<script>
import SiteHeader from '@/components/website/SiteHeader.vue'
import HeaderLunbo from '@/components/website/HeaderLunbo.vue'

import NavMenu from '@/components/website/NavMenu.vue'
export default {
	name: 'PcWebsiteIndex',
	components: { SiteHeader, HeaderLunbo, NavMenu },

	data() {
		return {

		};
	},

	mounted() {

	},

	methods: {

	},
};
</script>

<style lang="scss" scoped>

</style>